body {
  background-color: #fff;
  user-select: none;
}
body,
input,
button {
  color: #292b46;
  font-size: 21px;
  font-family: 'Kanit', sans-serif;
  font-weight: 400;
}
::-webkit-scrollbar {
  background: transparent;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
